<template>
  <div class="">
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'items-prices-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Désignation</th>
          <th
            v-for="(type, index) in customer_types"
            :key="index++"
            scope="col"
          >
            {{ type.fullName }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            {{ item.fullName }}
          </td>
          <td v-for="(type, index) in customer_types" :key="index++">
            <span
              v-if="item.prices"
              :class="
                !item.prices.find(
                  (p) => p.customerType_reference == type.reference
                )
                  ? 'text-warning fw-normal'
                  : ' '
              "
              class=" "
            >
              {{
                item.prices.find(
                  (p) => p.customerType_reference == type.reference
                ) | price
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getItems",
    }),
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
    }),
    // price: (prices, type_rererence) => {
    //   console.log(prices, type_rererence);
    //   // let item_price = prices.find(
    //   //   (p) => p.customerType_reference == type_rererence
    //   // );

    //   // let price = item_price.price;
    //   // let price = 0;
    //   // return price;
    // },
  },
  mounted() {
    this.$store.dispatch("item/getItemsPrices");
    this.$store.dispatch("customer/getCustomerTypes");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("item/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("item/getItemsPrices");
    },

    async listItems(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("item/getTodays");
      } else {
        return await this.$store.dispatch("item/getItemsPrices");
      }
    },
    async sort(value) {
      return await this.$store.commit("item/sort", value);
    },
  },
  filters: {
    price: function (value) {
      if (!value) return "X";
      return value.price;
    },
  },
};
</script>
